<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Application Information</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-application-status" label="Application Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-application-status"
                                                  v-model="applicationStatusFilter"
                                                  :options="applicationStatusOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="applicationStatusFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-last-name" label="Applicant Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-last-name"
                                                 v-model="lastNameFilter"
                                                 placeholder="Applicant Last Name" />
                                        <b-input-group-addon>
                                            <b-button @click="lastNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-ubcid" label="Applicant UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-ubcid"
                                                 v-model="ubcIdFilter"
                                                 placeholder="UBC ID"  />
<!--                                        <ubc-id id="search-ubcid" v-model="ubcIdFilter" input-class="form-control form-control-sm"/>-->
                                        <b-input-group-addon>
                                            <b-button @click="ubcIdFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-local-union" label="Applicant Local Union:">
                                    <b-input-group size="sm">
                                        <b-select id="search-local-union"
                                                  v-model="localUnionFilter"
                                                  :options="localOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="localUnionFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-classification" label="Applicant Classification:">
                                    <b-input-group size="sm">
                                        <b-select id="search-classification"
                                                  v-model="classificationFilter"
                                                  :options="classificationOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="classificationFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-decline-status"
                                              label="Application Declined Reason:"
                                              v-if="filteringForDeclined">
                                    <b-input-group size="sm">
                                        <b-select id="search-decline-status"
                                                  v-model="applicationDeclineStatusFilter"
                                                  :options="applicationDeclinedStatusOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="applicationDeclineStatusFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>

                                <!--
                                <b-form-group label-for="search-union-status" label="Applicant Union Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-union-status"
                                                  v-model="unionStatusFilter"
                                                  :options="unionStatusOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="unionStatusFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                                -->
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-mentor-last-name" label="Mentor Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-mentor-last-name"
                                                 v-model="mentorLastNameFilter"
                                                 placeholder="Mentor's Last Name"  />
                                        <b-input-group-addon>
                                            <b-button @click="mentorLastNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-contractor-name" label="Contractor Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-contractor-name"
                                                 v-model="contractorNameFilter"
                                                 placeholder="Contractor's Name" />
                                        <b-input-group-addon>
                                            <b-button @click="contractorNameFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-state" label="Contractor State/Province:">
                                    <b-input-group size="sm">
                                        <state-select :id="'search-state'" v-model="contractorStateFilter"/>
                                        <b-input-group-addon>
                                            <b-button @click="contractorStateFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-size="sm" label="Applied Date Range:">
                                    <picker-of-dates v-model="appliedDateRange" format="M/d/yyyy" clearable range/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-size="sm" label="Processed Date Range:">
                                    <picker-of-dates v-model="processedDateRange" format="M/d/yyyy" clearable range/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="search-programs" label="Program 1 Session:">
                                    <b-input-group size="sm">
                                        <b-select id="search-programs"
                                                  v-model="sessionFilter"
                                                  :options="programSessionOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="sessionFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import UbcId from '@/components/shared/UbcId.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import StateSelect from '@/components/shared/StateSelect.vue';
import _ from 'underscore';
import formatters, {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import trackDao from '@/dao/track_dao';

@Component({
    components: {
        UbcId,
        StateSelect,
        PickerOfDates
    }
})

export default class ApplicationInformation extends Vue {
    applicationStatusFilter = null;
    applicationDeclineStatusFilter = null;
    lastNameFilter = null;
    ubcIdFilter = null;
    localUnionFilter = null;
    classificationFilter = null;
    unionStatusFilter = null;
    mentorLastNameFilter = null;
    contractorNameFilter = null;
    contractorStateFilter = null;
    appliedDateRange = [null,null];
    processedDateRange = [null,null];
    sessionFilter = null;
    isSearching = false;

    get filteringForDeclined() {
        return !!this.applicationStatusFilter && this.applicationStatusFilter.toUpperCase() == 'DECLINED';
    }

    get searching() {
        return this.isSearching;
    }

    get applicationDeclinedStatusOptions() {
        const declinedStatuses = _.chain(this.$store.getters['applications/getApplicationStatuses'])
            .filter(s => s.type.toUpperCase() === 'DECLINED')
            .sortBy(s => s.description)
            .map(s => {
                return {
                    value: s.id,
                    text: s.description
                };
            })
            .value();
        // console.log(declinedStatuses);
        return declinedStatuses;
    }

    get applicationStatusOptions() {
        const statuses = _.groupBy(this.$store.getters['applications/getApplicationStatuses'], s => s.type);
        const statusTypes = _.sortBy(Object.keys(statuses), k => k);
        return statusTypes;
    }

    get localOptions() {
        const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf("%s - %s", l.districtName, l.councilName));
        return _.map(locals, (ls, districtCouncil) => {
            return {
                label: districtCouncil,
                options: _.map(ls, (local) => {
                    return {
                        value: local.displayId,
                        text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                    };
                })
            };
        });
    }

    get unionStatusOptions() {
        return [{
        //     value: 'ARREARS', // 45
        //     text: 'Arrears'
        // }, {
        //     value: 'ARRSASSESS', // 46
        //     text: 'Arrears Assess'
        // }, {
        //     value: 'ARREARSWRK', // 577
        //     text: 'Arrears Working Dues'
        // }, {
            value: 'GOODSTANDG', // 53
            text: 'Good Standing'
        }, {
        //     value: 'TRANSFER', // 58
        //     text: 'Transfer'
        // }, {
            value: 'OTHER',
            text: 'Other'
        }];
    }

    get classificationOptions() {
        return [{
            value: 'Member',
            text: 'Member'
        }, {
            value: 'Other',
            text: 'Other'
        }];
    }

    get programSessionOptions() {
        const sessions = this.$store.getters['common/program1Sessions'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    clearFilters() {
        this.applicationStatusFilter = null;
        this.applicationDeclineStatusFilter = null;
        this.lastNameFilter = null;
        this.ubcIdFilter = null;

        this.localUnionFilter = null;
        this.classificationFilter = null;
        this.unionStatusFilter = null;

        this.mentorLastNameFilter = null;
        this.contractorNameFilter = null;
        this.contractorStateFilter = null;

        this.appliedDateRange = [null,null];
        this.processedDateRange = [null,null];
        this.sessionFilter = null;
    }

    get args() {
        const args = {
            applicationStatus: this.applicationStatusFilter,
            applicationDeclineStatusId: this.applicationDeclineStatusFilter,
            lastName: this.lastNameFilter,
            ubcId: formatters.serializeAsUbcId(this.ubcIdFilter),
            localUnion: this.localUnionFilter,
            classification: this.classificationFilter,
            unionStatus: this.unionStatusFilter,
            mentorLastName: this.mentorLastNameFilter,
            contractorName: this.contractorNameFilter,
            contractorStateAbbreviation: !!this.contractorStateFilter ? this.contractorStateFilter.stateAbbreviation : null,
            applicationRange: this.appliedDateRange,
            processedRange: this.processedDateRange,
            sessionId: this.sessionFilter
        };
        return args;
    }

    async getCsv() {
        try {
            await download('POST', '/api/v1/export/application-information', JSON.stringify(this.args));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        try {
            await download('POST', '/api/v1/report/application-information', JSON.stringify(this.args));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Application Information', null, true));
        try {
            await this.$store.dispatch('common/loadProgram1Sessions');
            _.each([
                'applications/loadApplicationStatuses',
                'common/loadStates',
                'common/loadLocals'
            ], (action) => {
                this.$store.dispatch(action)
                    .catch(error => {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    });
            });
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

}
</script>


<style scoped>

</style>
